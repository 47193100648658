<template>
    <KCourse loader-class="MBcont" course-id="3" title="Роспись и парик" :items="items">
        <div class="MBtextcont">
            <h3>3.1 Замешиваем грунт и краску для тела</h3>
        </div>

        <VideoView video-id="d8950f369be6434986e1faa92b2acc46"/>

        <div class="MBtextcont">
            <h3>3.2 Покрываем детали грунтом и краской</h3>
        </div>

        <VideoView video-id="fa3f7ebaca4645baae535ec8472566b9"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Образ", url:"/mycourses/draw/1"},
                { title: "Подготовка", url:"/mycourses/draw/2"},
                { title: "Грунтуем и красим", url:"/mycourses/draw/3"},
                { title: "Расписываем", url:"/mycourses/draw/4"},
                { title: "Делаем парик", url:"/mycourses/draw/5"},
            ],
        }
        }
    }
</script>